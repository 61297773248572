html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .main-content {
    align-items: center; /* Center items on smaller screens */
  }
}

@media (max-width: 768px) {
  .main-content {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .main-content {
    flex-direction: column;
    width: 100%;
    padding: 10px;
    align-items: center;
  }
}

header {
  height: 60px; /* Adjust to the height of ToolBar */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.content-wrapper {
  margin-top: 60px; /* Matches the height of the header */
  margin-bottom: 60px; /* Matches the height of the footer */
  flex: 1;
  width: 100%;
  max-width: 1024px;
}
