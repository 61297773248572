body {
  font-family: "League Spartan", serif;
  font-optical-sizing: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/background-cart-2.png");
  background-blend-mode: lighten;
  background-color: rgb(255, 128, 0);
  background-repeat: repeat; /* Options: no-repeat, repeat, repeat-x, repeat-y */
  background-size: contain; /* Options: cover, contain, or specific dimensions */
  background-position: center; /* Options: center, top, bottom, left, right */
  background-attachment: fixed; /* Makes the background stay fixed during scroll */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
