.marketplace-img {
  width: 20vw;
  max-width: 200px;
  margin: 10px;
  cursor: pointer;
}

.marketplace-img:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .marketplace-img {
    flex-direction: column;
    width: 20vw;
  }
}
